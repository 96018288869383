svg {
  overflow: visible;
  user-select: none;
  max-width: 350px;
}

.mygraph {
  max-width: 350px;
  margin: "10px";
  z-index: 100;
  position: relative;
  user-select: none;
}

.chart-container { 
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  box-sizing: border-box;
  padding: 1em 2em;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loading-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100;
  font-size: 2.5em;
  font-weight: bold;
}