@import url('https://rsms.me/inter/inter.css');
/* Importing Roboto font from google fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');


/* 
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * Better if we only keep the top-level CSS rules in this file. Ideally, better to name it as
 * index.css so others will easily know this contains root CSS rules. Top-level means element specific
 * like the html, body, textarea, etc which should apply across all the instances of the element in
 * the site by default unless we override it on component-level.
 * Also, we can create an App.css file for having the CSS rules specific to the main App component.
 * This would be helpful in cases where we can have multiple App components in the top-level but if
 * there would be only single App component then we can simply omit this file and have those rules
 * also be kept in the index.css. Also, when writing CSS rules better to follow a specific convention
 * like BEM naming convention or simple kebab-case format.
 */

html,
body
{
  position: absolute;
  width: 100vw;
  height: 100vh;
  margin: 0;
  background: #f5f5f5;
  /*--max-width: 1120px;*/
  --grey-500: #64748b;
  font-family: 'Roboto', sans-serif;
}

body {
  background: #f0f0f0;
}

#root {
  height: 100vh;
  background: #f5f5f5;
  --grey-500: #64748b;
  box-sizing: border-box;
}

.mycanvas {
  /*z-index:0;*/
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.container {
  position: relative;
  padding: 2em;
  width: 100%;
  max-width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}

/* 
 * ================================
 * Commented by : Suvrat Ram Joshi
 * ================================
 * Limit the use of !important property as much as possible. If we can fix an issue
 * without using this property then better to skip it. CSS works in a cascading way,
 * so having this in any one of the intermediate rules of the cascade could make it
 * very difficult to debug.
 * 
 * We can also keep 0 only instead of 0px. 
 */
.canvas {
  position: fixed !important;
  top: 0px;
  left: 0px;
  width: 100vw !important;
  height: 100vh !important;
}

.text {
  line-height: 1em;
  text-align: left;
  font-size: 8em;
  word-break: break-word;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.link {
  color: var(--grey-500);
}

.active {
  /*color: var(--primary-500);*/
  color: red;
}

.navbar {
  z-index: 2;
  /*z-index: auto;*/
  position: relative;
  text-align: center;
  /*margin: 0 auto;*/
  /*text-align: center;*/
  display: flex;
  /*width: 100vw;*/
  /*max-width: var(--max-width);*/
  /*margin: 0 0;*/
  pointer-events: all;
  /*display: ;*/
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
  margin:0 auto;
  margin-bottom: 3em;
  clear:left;
  height:auto;
}

.navbar hr {
  margin: 2px;
  margin-right: 10px;
  flex-shrink: 0;
  border-width: 0px thin 0px 0px;
  border-style: solid;
  border-color: #B7B7B7;
  height: auto;
  align-self: stretch;
}

.navbar > a:nth-of-type(1) {
  color: #595959;
  font-size: 1.2em;
  text-decoration: none;
}

.brand {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand * {
  margin: 0 0.3em;
}

.navbar-links a {
  margin: 0 0.5em;
}

.toggle-button {
  position: absolute;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 25px;
  height: 20px;
}

.toggle-button .bar {
  display: block;
  height: 3px;
  width: 100%;
  background-color: #666666;
  border-radius: 10px;
}

@media (max-width: 610px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .navbar hr {
    display: none;
  }

  .toggle-button {
    display: flex;
  }

  .navbar-links {
    width: 100%;
    display: none;
    flex-direction: column;
  }

  .navbar-links a {
    padding: .5em 0em;
  }

  .navbar-links a:hover {
    background-color: rgb(247, 247, 223, 0.6);
  }

  .navbar-links.active {
    display: flex;
  }
}

.section {
  display: flex;
  padding: 2rem 0;
  /* width: 90vw;
  max-width: var(--max-width); */
  margin: 0 auto;
}

.about {
  background-color: white;
  width: 100%;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  padding: 0em 1em 2.5em 1em;
}

.about h1 {
  font-size: 1.8em;
  font-weight: 400;
}

.about span {
  display: block;
  margin-top: 2em;
}

/*
 * =============
 * Custom Styles
 * =============
 */

div#unsupported-browser {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 60px;
  z-index: 101;
  background-color: rgba(255, 255, 255, 0.5);
  width: 250px;
  height: 50px;
  padding: 1em;
  line-height: 1.5em;
  font-size: 0.8em;
}

/*
 * =============
 * Modal-Handler
 * =============
 */

.robotarm__div--modal-handler {
  position: absolute;
  right: 30px;
  bottom: 50px;
  z-index: 1;
  cursor: pointer;
}

.robotarm__div--modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.robotarm__div--modal .content {
  background-color: rgba(255, 255, 255, 0.96);
  margin: auto;
  border: none;
  border-radius: 10px;
  width: 50%;
  height: 75%;
  overflow-y: auto;
  box-shadow: 0 0 2px 0 #e6e6e6;
}

.robotarm__div--modal .header {
  padding: 5px 20px;
}

.robotarm__div--modal .header > span.close {
  color: #b7b7b7;
  float: right;
  font-size: 40px;
}

.robotarm__div--modal .header > .close:hover,
.robotarm__div--modal .header > .close:focus {
  text-decoration: none;
  cursor: pointer;
}

.robotarm__div--modal .body {
  padding: 50px;
  padding-top: 5px;
}

.robotarm__div--modal .body h1 {
  font-weight: 400;
}

.robotarm__div--modal .body h2 {
  font-weight: 400;
}

.robotarm__div--modal .body .image-container {
  display: flex;
  justify-content: space-between;
  margin: 0 40px;
}

.robotarm__div--modal .body .image-container > div {
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
}

.robotarm__div--modal .body .image-container > div > img {
  width: 100%;
  height: 100%;
  border: 0.5px solid #333;
}

.robotarm__div--modal .body .image-container > div > span {
  padding: 0 10px;
}

/* Media query for mobile screen - xs */
@media only screen and (max-width: 575px) {

}

/* Media query for tablet screen - sm */
@media only screen and (min-width: 576px) {

}

/* Media query for laptop/small screen - md */
@media only screen and (min-width: 768px) {

}

/* Media query for desktop/large screen - lg */
@media only screen and (min-width: 992px) {

}

/* Media query for extra large screen - xl */
@media only screen and (min-width: 1200px) {
  
}

/* Media query for extra large screen - xxl */
@media only screen and (min-width: 1400px) {
  
}