
/*
 * =======
 * Slider
 * =======
 */

.robotarm__div--slider-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  height: 60px;
  left: 0;
  right: 0;
  bottom: 60px;
  text-align: center;
  z-index: 1;
}

.continuum__div--slider-container > div {
  display: flex;
  align-items: center;
}

.continuum__div--slider-container label {
  font-size: 20px;
  font-weight: 500;
  color: #959595;
  position: absolute; /* Made the label absolute position to avoid affecting the flex layout */
  direction: rtl; /* To write the text from right to left */
  width: 100px;
  transform: translate(-110px, 0); /* To shift the text on left. Here 110 = 100 (width) + 10 (margin-right) */
}

.robotarm__div--slider-container .slider {
  -webkit-appearance: none;
  width: 400px;
  height: 8px;
  border-radius: 2px;
  background: #b7b7b7;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.robotarm__div--slider-container .slider:hover {
  opacity: 1;
}

.robotarm__div--slider-container .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 30px;
  border-radius: 4px;
  background: #d9d9d9;
  cursor: pointer;
}

.robotarm__div--slider-container .slider::-moz-range-thumb {
  width: 22px;
  height: 30px;
  background: #d9d9d9;
  cursor: pointer;
}

/* Media query for mobile screen - xs */
@media only screen and (max-width: 575px) {
  .robotarm__div--slider-container {
    bottom: 100px;
  }

  .robotarm__div--slider-container .slider {
    width: 300px;
  }
}

/* Media query for tablet screen - sm */
@media only screen and (min-width: 576px) {
  
}

/* Media query for laptop/small screen - md */
@media only screen and (min-width: 768px) {

}

/* Media query for desktop/large screen - lg */
@media only screen and (min-width: 992px) {

}

/* Media query for extra large screen - xl */
@media only screen and (min-width: 1200px) {
  
}

/* Media query for extra large screen - xxl */
@media only screen and (min-width: 1400px) {
  
}

