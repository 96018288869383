/*
 * =======
 * Footer
 * =======
 */

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.6em;
  font-size: 0.8em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
}

footer span:nth-child(1) {
  grid-column-start: 2;
}

footer span:nth-child(2) {
  color: #666666;
  margin-left: auto;
}

/*
 * =============
 * Speed Dial
 * =============
 */

 .speed-dial__div {
  position: absolute;
  right: 30px;
  bottom: 50px;
  z-index: 1;
  cursor: pointer;
}

/*
 * ======
 * Modal
 * ======
 */

 .infomodal__div--modal {
  display: none;
  position: fixed;
  z-index: 3;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.infomodal__div--modal .content {
  background-color: rgba(255, 255, 255, 0.96);
  margin: auto;
  border: none;
  border-radius: 10px;
  width: 50%;
  height: 75%;
  overflow-y: auto;
  box-shadow: 0 0 2px 0 #e6e6e6;
}

.infomodal__div--modal .header {
  padding: 5px 20px;
}

.infomodal__div--modal .header > span.close {
  color: #b7b7b7;
  float: right;
  font-size: 40px;
}

.infomodal__div--modal .header > .close:hover,
.infomodal__div--modal .header > .close:focus {
  text-decoration: none;
  cursor: pointer;
}

.infomodal__div--modal .body {
  padding: 50px;
  padding-top: 5px;
}

.infomodal__div--modal .body h1 {
  font-weight: 400;
}

.infomodal__div--modal .body h2 {
  font-weight: 400;
}

.infomodal__div--modal .body .image-container {
  display: flex;
  justify-content: space-between;
  margin: 0 40px;
}

.infomodal__div--modal .body .image-container > div {
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
}

.infomodal__div--modal .body .image-container > div > img {
  width: 100%;
  height: 200px; /* CONFIGURE: Adjust the height property for the image */
  object-fit: cover;
  border: 0.5px solid #333;
}

.infomodal__div--modal .body .image-container > div > span {
  padding: 0 10px;
}

.infomodal__div--modal .body .image-container-single {
  display: flex;
  justify-content: center;
  margin: 0 40px;
}

.infomodal__div--modal .body .image-container-single > div {
  display: flex;
  flex-direction: column;
  flex-basis: 80%;
}

.infomodal__div--modal .body .image-container-single > div > img {
  width: 100%;
  height: 100%;
  border: 0.5px solid #333;
}

.infomodal__div--modal .body .image-container-single > div > span {
  padding: 0 10px;
}

/* Media query for mobile screen - xs */
@media only screen and (max-width: 575px) {
  footer {
    grid-template-columns: 1fr 1fr;
  }

  footer span:nth-child(1) {
    grid-column-start: 1;
  }

  .infomodal__div--modal .content {
    width: 90%;
    height: 80%;
  }

  .infomodal__div--modal .body {
    padding: 25px;
    padding-top: 0;
    text-align: justify;
  }

  .infomodal__div--modal .body .image-container {
    flex-direction: column;
    margin: 10px 0;
  }

  .infomodal__div--modal .body .image-container > div {
    margin: 5px 0;
  }
}

/* Media query for tablet screen - sm */
@media only screen and (min-width: 576px) {
  
}

/* Media query for laptop/small screen - md */
@media only screen and (min-width: 768px) {

}

/* Media query for desktop/large screen - lg */
@media only screen and (min-width: 992px) {

}

/* Media query for extra large screen - xl */
@media only screen and (min-width: 1200px) {
  
}

/* Media query for extra large screen - xxl */
@media only screen and (min-width: 1400px) {
  
}

